


import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { db } from '../Components/Register/Firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import bcrypt from 'bcryptjs';
import './AdminPanel.css';

const AdminPanel = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [data, setData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [adminCredentials, setAdminCredentials] = useState({});
  const [view, setView] = useState('users'); // 'users' or 'subscriptions'

  useEffect(() => {
    const fetchAdminCredentials = async () => {
      try {
        const docRef = doc(db, 'AdminCredentials', 'admin');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAdminCredentials(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching admin credentials:', error);
      }
    };

    fetchAdminCredentials();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username === adminCredentials.username) {
      try {
        const passwordMatch = await bcrypt.compare(password, adminCredentials.passwordHash);
        if (passwordMatch) {
          setIsAuthenticated(true);
          fetchData();
          fetchSubscriptionData();
        } else {
          alert('Geçersiz kullanıcı adı veya şifre');
        }
      } catch (error) {
        console.error('Error comparing passwords:', error);
      }
    } else {
      alert('Geçersiz kullanıcı adı veya şifre');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUsername('');
    setPassword('');
  };

  const fetchData = async () => {
    try {
      const snapshot = await getDocs(collection(db, 'Users'));
      const dataList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setData(dataList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchSubscriptionData = async () => {
    try {
      const snapshot = await getDocs(collection(db, 'subscribers'));
      const subscriptionList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSubscriptionData(subscriptionList);
    } catch (error) {
      console.error("Error fetching subscription data: ", error);
    }
  };

  return (
    <Container>
      {!isAuthenticated ? (
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formBasicUsername">
                <Form.Label>Kullanıcı Adı</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kullanıcı adı"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Şifre</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Şifre"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Giriş Yap
              </Button>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={3} className="mb-3">
            <div className="admin-links">
              <Button variant="link" onClick={() => setView('users')}>
                Üyelikler
              </Button>
              <Button variant="link" onClick={() => setView('subscriptions')}>
                Abonelikler
              </Button>
            </div>
          </Col>
          <Col xs={12} md={9}>
            <h1>Admin Panel</h1>
            {view === 'users' && (
              <>
                <h2 className="admin-section-title">Üyelikler</h2>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Job</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.job}</td>
                        <td>{item.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
            {view === 'subscriptions' && (
              <>
                <h2 className="admin-section-title">Abonelikler</h2>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptionData.map(item => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
          <Button variant="primary" onClick={handleLogout} className="logout-panel">
            Çıkış Yap
          </Button>
        </Row>
      )}
    </Container>
  );
};

export default AdminPanel;
