// WhatsAppButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import  './Whatsapp.css'

const WhatsAppButton = ({ phoneNumber }) => {
  const handleClick = () => {
    // WhatsApp'a yönlendirme veya açma işlemleri burada yapılabilir
  };

  const whatsappNumber = '+905336143702';

  return (
   <div className='whatsapp'>
        <a
          href={`https://wa.me/${whatsappNumber}`}
          className='whatsapp-button'
          target='_blank'
          rel='noopener noreferrer'
        > 
          <FontAwesomeIcon icon={faWhatsapp} className='whatsapp-icon' />
        </a>
      </div>
  );
};

export default WhatsAppButton;
