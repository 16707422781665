


import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import Logo from '../../Picture/logo.png';
import { auth } from "../Register/Firebase"; 
import './Header.css';
import Cart from '../../Picture/cart.png';


const Header = () => {
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user); 
    });

    return unsubscribe;
  }, []);

  const handleNavItemClick = () => {
    setNavbarExpanded(false); 
  };

  const getCartItemCount = () => {
    return 2; // Replace with actual logic
  };

  useEffect(() => {
    setCartItemCount(getCartItemCount());
    console.log('Cart Item Count:', getCartItemCount()); // Debug log
  }, []);

  const handleNavDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
  };




// Sayfa yüklendiğinde ve her scroll olayında adjustContentPosition fonksiyonunu çağır



  return (
    <Navbar  collapseOnSelect expand="md" variant="dark" expanded={navbarExpanded} >
      <Container fluid >
        <Navbar.Brand as={NavLink} to="/" className="brand">
          <img src={Logo} alt="Logo" className="brand-logo" /> 
          <span className="brand-name">Şen Diş Deposu</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setNavbarExpanded(!navbarExpanded)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navlink ml-auto">
            <Nav.Link as={NavLink} to="/" exact onClick={handleNavItemClick}>Anasayfa</Nav.Link>
            <Nav.Link as={NavLink} to="/hakkımızda" onClick={handleNavItemClick}>Hakkımızda</Nav.Link>
            <NavDropdown
              title="Ürünler"
              id="products-dropdown"
              show={isDropdownOpen} // Control dropdown visibility with state
              onClick={handleNavDropdownClick} // Toggle dropdown on click
            >
              <NavDropdown.Item as={NavLink} to="/products/category/akrilik" onClick={handleNavItemClick}>Akrilik Dişler</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/products/category/elaletleri" onClick={handleNavItemClick}>El Aletleri</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/products/category/frezler" onClick={handleNavItemClick}>Frezler</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/products/category/ksm" onClick={handleNavItemClick}>Klinik Sarf Malzemeleri</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/products/category/labaletleri" onClick={handleNavItemClick}>Laboratuvar Aletleri</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/products/category/lsm" onClick={handleNavItemClick}>Laboratuvar Sarf Malzemeleri</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/products/category/ögrenci" onClick={handleNavItemClick}>Öğrenci Malzemeleri</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="/blog" onClick={handleNavItemClick}>Blog</Nav.Link>
            <Nav.Link as={NavLink} to="/iletişim" onClick={handleNavItemClick}>İletişim</Nav.Link>
            {isLoggedIn ? (
              < >
                <Nav.Link as={NavLink} to="/cart" onClick={handleNavItemClick} className="cart-link">
                  <img src={Cart} alt="Cart" className="cart-icon" />
                  {/* {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>} */}
                </Nav.Link>

                <Nav.Link as={NavLink} to="/profile" onClick={handleNavItemClick} className="btn-register">Profil</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={NavLink} to="/register" onClick={handleNavItemClick} className="btn-register">Kayıt Ol</Nav.Link>
                <Nav.Link as={NavLink} to="/login" onClick={handleNavItemClick} className="btn-register">Giriş Yap</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

