import { Suspense, lazy } from "react";
import { Route, Routes,Navigate } from "react-router-dom";
import Header from "./Components/Header/Header.jsx";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CartProvider } from "./Components/Cart/CartContext.js";
import './App.css'
import React, { useEffect, useState } from "react";
import WhatsAppButton from "./Components/Whattsapp/Whattsapp.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./Components/Register/Firebase.jsx";
import AdminPanel from "./Adminpanel/AdminPanel.jsx";


const AppHome = lazy(() => import("./Pages/AppHome"));
const AppAbout = lazy(() => import("./Pages/AppAbout"));
const AppAkrilik = lazy(() => import("./Pages/AppAkrilik"));
const AppElaletleri = lazy(() => import("./Pages/AppElaletleri"));
const AppFrezler = lazy(() => import("./Pages/AppFrezler"));
const AppKsm = lazy(() => import("./Pages/AppKsm"));
const AppLabaletleri = lazy(() => import("./Pages/AppLabaletleri"));
const AppLsm = lazy(() => import("./Pages/AppLsm.jsx"));
const AppStudent = lazy(() => import("./Pages/AppStudent"));
const Appblog = lazy(() => import("./Pages/Appblog"));
const AppContact = lazy(() => import("./Pages/AppContact.jsx"));
const DataDetail = lazy(() => import("./Pages/DataDetails/DataDetail.jsx"));
const AppRegister = lazy(() => import("./Pages/AppRegister.jsx"));
const AppLogin = lazy(() => import("./Pages/AppLogin.jsx"));
const AppCart = lazy(() => import("./Pages/AppCart.jsx"));
const AppProfile = lazy(() => import("./Pages/AppProfile.jsx"));







function App() {
    const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });


  
  return (

      
         <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner"></div>
        <header id="header">
          <Header />
        </header>
        <main>
       
          <Suspense fallback={<div>Loading...</div>}>
           
             <CartProvider>
            <Routes>
       
              <Route  path="/" element={<AppHome />} />
              <Route path="/hakkımızda" element={<AppAbout />} />
               <Route path="/product/:id" element={<DataDetail />} />
              <Route path="/products/category/akrilik" element={<AppAkrilik />} />
              <Route path="/products/category/elaletleri" element={<AppElaletleri />} />
              <Route path="/products/category/frezler" element={<AppFrezler />} />
              <Route path="/products/category/ksm" element={<AppKsm />} />
              <Route path="/products/category/labaletleri" element={<AppLabaletleri />} />
              <Route path="/products/category/lsm" element={<AppLsm />} />
              <Route path="/products/category/ögrenci" element={<AppStudent />} />
              <Route path="/blog" element={<Appblog />} />
              <Route path="/iletişim" element={<AppContact />} />
              <Route
                path="/"
                element={user ? <Navigate to="/profile" /> : <AppLogin />}
              />
                <Route path="/register" element={<AppRegister />} />  
                <Route path="/login" element={<AppLogin />} /> 
                <Route path="/profile" element={<AppProfile />} /> 
              
                <Route path="/cart" element={<AppCart />} />  
                          <Route path="/depom" element={<AdminPanel />} />   
            
   
                
              <Route path="/whatsapp" element={<WhatsAppButton />} />
           
              
                      

            </Routes>
                <ToastContainer />
          </CartProvider>
        
          </Suspense>
      
        </main>
            </div>
      </div>
    
    
  );
}

export default App;

