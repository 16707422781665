import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './Components/Header/AuthContext';

console.log("Environment Variables Check");
console.log("REACT_APP_ADMIN_USERNAME:", process.env.REACT_APP_ADMIN_USERNAME);
console.log("REACT_APP_ADMIN_PASSWORD_HASH:", process.env.REACT_APP_ADMIN_PASSWORD_HASH);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <AuthProvider>
  <BrowserRouter basename="/">
  
  
      <App />
  
  </BrowserRouter>
    </AuthProvider>
    

);

